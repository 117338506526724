import axios from "axios";
import { financeUrl, getHeader, masterUrl, voucherUrl } from "@/constant/config";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  state: {
    accountList: [],
    accountTypeList: [],
    bookList: [],
    book: {},
    cashBankTypeList: [],
    cashBank: {},
    fixAssetTypeList: [],
    voucherCategoryList: [],
    journal: {},
    showCashBankImportModal: false,
    showCashBankImportFormModal: false,
    showCashBankDetailFormModal: false,
    showJournalShowModal: false,
    showCashbankFormModal: false,
    showAccountImportModal: false,
    showFixedAssetProcessModal: false,
    showJournalModal: false,
    showJournalFormModal: false,
    showBookFormModal: false,
    showCashBankTransferFormModal: false,
    showCashBankTransferListModal: false,
    showAccountTypeFormModal: false,
    showProfitLossProcessModal: false
  },
  mutations: {
    SET_CASH_BANK (state, obj) {
      state.cashBank = obj
    },
    SET_ACCOUNT_LIST (state, obj) {
      state.accountList = obj
    },
    SET_ACCOUNT_TYPE_LIST (state, obj) {
      state.accountTypeList = obj
    },
    SET_CASH_BANK_TYPE_LIST (state, obj) {
      state.cashBankTypeList = obj
    },
    SET_FIX_ASSET_TYPE_LIST (state, obj) {
      state.fixAssetTypeList = obj
    },
    SET_VOUCHER_CATEGORY_LIST (state, obj) {
      state.voucherCategoryList = obj
    },
    SET_JOURNAL (state, obj) {
      state.journal = obj
    },
    SET_CASH_BANK_IMPORT_MODAL (state, obj) {
      state.showCashBankImportModal = obj
    },
    SET_CASH_BANK_IMPORT_FORM_MODAL (state, obj) {
      state.showCashBankImportFormModal = obj
    },
    SET_CASH_BANK_DETAIL_FORM_MODAL ( state, obj) {
      state.showCashBankDetailFormModal = obj
    },
    SET_SHOW_JOURNAL_SHOW_MODAL (state, obj) {
      state.showJournalShowModal = obj
    },
    SET_SHOW_CASH_BANK_FORM_MODAL (state, obj) {
      state.showCashbankFormModal = obj
    },
    SET_SHOW_ACCOUNT_IMPORT_MODAL (state, obj) {
      state.showAccountImportModal = obj
    },
    SET_SHOW_FIXED_ASSET_PROCESS_MODAL (state, obj) {
      state.showFixedAssetProcessModal = obj
    },
    SET_SHOW_JOURNAL_MODAL (state, obj) {
      state.showJournalModal = obj
    },
    SET_SHOW_JOURNAL_FORM_MODAL (state, obj) {
      state.showJournalFormModal = obj
    },
    SET_SHOW_BOOK_FORM_MODAL (state, obj) {
      state.showBookFormModal = obj
    },
    SET_BOOK_LIST (state, obj) {
      state.bookList = obj
    },
    SET_BOOK (state, obj) {
      state.book = obj
    },
    SET_SHOW_CASH_BANK_TRANSFER_FORM_MODAL (state, obj) {
      state.showCashBankTransferFormModal = obj
    },
    SET_SHOW_CASH_BANK_TRANSFER_LIST_MODAL (state, obj) {
      state.showCashBankTransferListModal = obj
    },
    SET_SHOW_ACCOUNT_TYPE_FORM_MODAL (state, obj) {
      state.showAccountTypeFormModal = obj
    },
    SET_SHOW_PROFIT_LOSS_PROCESS_MODAL (state, obj) {
      state.showProfitLossProcessModal = obj
    }
  },
  actions: {
    enableShowProfitLossProcessModal ({ commit }) {
      commit('SET_SHOW_PROFIT_LOSS_PROCESS_MODAL', true)
    },
    disableShowProfitLossProcessModal ({ commit }) {
      commit('SET_SHOW_PROFIT_LOSS_PROCESS_MODAL', false)
    },
    enableShowAccountTypeFormModal ({ commit }) {
      commit('SET_SHOW_ACCOUNT_TYPE_FORM_MODAL', true)
    },
    disableShowAccountTypeFormModal ({ commit }) {
      commit('SET_SHOW_ACCOUNT_TYPE_FORM_MODAL', false)
    },
    enableShowCashBankTransferFormModal ({ commit }) {
      commit('SET_SHOW_CASH_BANK_TRANSFER_FORM_MODAL', true)
    },
    enableShowCashBankTransferListModal ({ commit }) {
      commit('SET_SHOW_CASH_BANK_TRANSFER_LIST_MODAL', true)
    },
    disableShowCashBankTransferListModal ({ commit }) {
      commit('SET_SHOW_CASH_BANK_TRANSFER_LIST_MODAL', false)
    },
    disableShowCashBankTransferFormModal ({ commit }) {
      commit('SET_SHOW_CASH_BANK_TRANSFER_FORM_MODAL', false)
    },
    enableShowBookFormModal ({ commit }) {
      commit('SET_SHOW_BOOK_FORM_MODAL', true)
    },
    disableShowBookFormModal ({ commit }) {
      commit('SET_SHOW_BOOK_FORM_MODAL', false)
    },
    enableShowJournalFormModal ({ commit }) {
      commit('SET_SHOW_JOURNAL_FORM_MODAL', true)
    },
    disableShowJournalFormModal ({ commit }) {
      commit('SET_SHOW_JOURNAL_FORM_MODAL', false)
    },
    enableShowJournalModal ({ commit }) {
      commit('SET_SHOW_JOURNAL_MODAL', true)
    },
    disableShowJournalModal ({ commit }) {
      commit('SET_SHOW_JOURNAL_MODAL', false)
    },
    enableShowAccountImportModal ({ commit }) {
      commit('SET_SHOW_ACCOUNT_IMPORT_MODAL', true)
    },
    disableShowAccountImportModal ({ commit }) {
      commit('SET_SHOW_ACCOUNT_IMPORT_MODAL', false)
    },
    enableShowCashBankFormModal ({ commit }) {
      commit('SET_SHOW_CASH_BANK_FORM_MODAL', true)
    },
    disableShowCashBankFormModal ({ commit }) {
      commit('SET_SHOW_CASH_BANK_FORM_MODAL', false)
    },
    enableJournalShowModal ({ commit }) {
      commit('SET_SHOW_JOURNAL_SHOW_MODAL', true)
    },
    disableJournalShowModal ({ commit }) {
      commit('SET_SHOW_JOURNAL_SHOW_MODAL', false)
    },
    enableCashBankImportModal ({ commit }) {
      commit('SET_CASH_BANK_IMPORT_MODAL', true)
    },
    disableCashBankImportModal ({ commit }) {
      commit('SET_CASH_BANK_IMPORT_MODAL', false)
    },
    enableCashBankImportFormModal ({ commit }) {
      commit('SET_CASH_BANK_IMPORT_FORM_MODAL', true)
    },
    disableCashBankImportFormModal ({ commit }) {
      commit('SET_CASH_BANK_IMPORT_FORM_MODAL', false)
    },
    enableCashBankDetailFormModal ({ commit }) {
      commit('SET_CASH_BANK_DETAIL_FORM_MODAL', true)
    },
    disableCashBankDetailFormModal ({ commit }) {
      commit('SET_CASH_BANK_DETAIL_FORM_MODAL', false)
    },
    enableFixedAssetProcessModel ({ commit }) {
      commit('SET_SHOW_FIXED_ASSET_PROCESS_MODAL', true)
    },
    disableFixedAssetProcessModal ({ commit }) {
      commit('SET_SHOW_FIXED_ASSET_PROCESS_MODAL', false)
    },
    async getBookList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/book', { headers: getHeader() })
        commit("SET_BOOK_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getBook ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/book/' + payload.id, { headers: getHeader() })
        commit('SET_BOOK', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getCashBank ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/cash-bank/' + payload.id, { headers: getHeader() })
        commit('SET_CASH_BANK', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getJournal ({ commit }, payload) {
      try {
        const data = await axios.get(financeUrl + '/journal/' + payload.id, { headers: getHeader() })
        commit('SET_JOURNAL', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getVoucherCategoryList ({ commit }) {
      try {
        const data = await axios.get(voucherUrl + '/category', { headers: getHeader() })
        commit('SET_VOUCHER_CATEGORY_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getFixAssetTypeList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/fixed-asset/type', { headers: getHeader() })
        commit("SET_FIX_ASSET_TYPE_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getCashBankTypeList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/cash-bank/type', { headers: getHeader() })
        commit("SET_CASH_BANK_TYPE_LIST", data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getAccountTypeList ({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/account/type', { headers: getHeader() })
        commit('SET_ACCOUNT_TYPE_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    },
    async getAccountList({ commit }) {
      try {
        const data = await axios.get(financeUrl + '/account', { headers: getHeader() })
        commit('SET_ACCOUNT_LIST', data.data.data)
      } catch (error) {
        console.log(error)
        console.log(error.message)
        toast.error(error.message, { timeout: 2000 })
      }
    }
  }
};
